<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-employers">
      Employers
    </div>
    <div class="box-style overflow-auto">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div class="grid grid-cols-3 gap-1">
          <!-- <div class="col-span-2">
            <div><label for="textbox-filter">Filter</label></div>
            <input
              id="textbox-filter"
              type="text"
              v-on:keyup="filter($event)"
              class="border-steel-gray rounded h-8 w-2/3"
            />
          </div> -->
          <div class="col-span-3 text-right">
            <router-link to="/admin/sysadmin/employers/add">
              <Button text="Add" />
            </router-link>
          </div>
        </div>
        <dynamic-table
          :records="this.filteredListOfEmployers"
          :fields="tableConfig"
          :paginationPage="paginationPage"
          :pagination-total-items="paginationTotalItems"
          pagination
          @pageChanged="nextPage($event)"
          no-data-msg="No employers to display for the current filter"
          striped
          hover
        >
          <template #cell(open-employer)="{ item }">
            <router-link
              :to="'/admin/sysadmin/employers/view/' + item.employer_id"
            >
              <button>
                <font-awesome-icon
                  icon="plus"
                  class="text-purple"
                  aria-label="view employer"
                />
              </button>
            </router-link>
          </template>

          <template #cell(Edit)="{ item }">
            <div class="text-center w-100">
              <router-link
                :to="'/admin/sysadmin/employers/edit/' + item.employer_id"
              >
                <button>
                  <font-awesome-icon icon="edit" aria-label="edit employer" />
                </button>
              </router-link>
            </div>
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { get } from "@/composables/httpUtil";
import Button from "@/components/reusable-components/base-level-components/Button";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { mapGetters } from "vuex";

export default {
  name: "StudentRecords",
  components: { LoadingSpinner, Button, DynamicTable },
  data() {
    return {
      page: 1,
      listOfEmployers: [],
      filteredListOfEmployers: undefined,
      filterText: "",
      isLoading: false,
      employer: undefined,
      paginationPage: 1,
      paginationTotalItems: 0,

      tableConfig: [
        {
          display: " ",
          name: "open-employer",
          sortable: false,
          class: "",
        },
        {
          display: "Name",
          name: "employer_name",
          sortable: false,
          class: "",
        },
        {
          display: "Website",
          name: "web_site",
          sortable: false,
          class: "",
        },
        {
          display: "Employees",
          name: "number_employees",
          sortable: false,
          class: "",
        },
        {
          display: "Edit",
          name: "Edit",
          sortable: false,
          class: "text-center",
          icon: "edit",
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const url = `admin/employers?page=${this.paginationPage}&limit=25`;

      this.isLoading = true;
      get(url)
        .then((results) => {
          this.listOfEmployers = results.data;
          this.filteredListOfEmployers = results.data;

          this.paginationTotalItems = results.count;
          this.paginationTotalPages = results.total_pages;
          this.isLoading = false;
        })
        .catch((error) => {
          //TODO: do something with this error and display it to the user
          console.log("error in catch statement", error);
          this.isLoading = false;
        });
    },
    filter($event) {
      this.filterText = $event.target.value.trim().toUpperCase();
      if (this.filterText !== "") {
        this.filteredListOfEmployers = this.listOfEmployers.filter(
          this.filterCondition
        );
      } else {
        this.filteredListOfEmployers = this.listOfEmployers;
      }
    },
    filterCondition(record) {
      if (
        record?.employer_name.toUpperCase().includes(this.filterText) ||
        record?.address.city.toUpperCase().includes(this.filterText) ||
        record?.address.state.toUpperCase().includes(this.filterText) ||
        this.lookup_states[record?.address.state]
          ?.toUpperCase()
          .includes(this.filterText) //checks to see if someone is typing the name of the state rather than the abbreviation
      ) {
        return true;
      } else {
        return false;
      }
    },
    reset() {
      this.listOfEmployers = [];
      this.filteredListOfEmployers = undefined;
      this.isLoading = false;
      this.filterText = "";
    },
    back() {
      this.$router.push("/admin/sysadmin/employers");
    },
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters(["lookup_states"]),
  },
};
</script>

<style scoped></style>
